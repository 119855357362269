@tailwind base;
@tailwind components;
@tailwind utilities;

.display-linebreak {
    white-space: pre-line;
  }

ol {
  list-style: decimal;
  padding-left: 2rem;
}