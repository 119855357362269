/* custom-quill.css */

 .ql-editor {
    background-color: #1f2937; /* Couleur de fond sombre */
    color: #ffffff; /* Couleur du texte clair */
  }

  .ql-toolbar .ql-stroke {
    fill: none;
    stroke: #fff;
}

.ql-toolbar .ql-fill {
    fill: #fff;
    stroke: none;
}

.ql-toolbar .ql-picker {
    color: #fff;
}

.ql-toolbar .ql-picker-item {
    color: #000000;
}
